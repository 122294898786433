import React from 'react'
import { Badge, Container, Col, Row } from 'react-bootstrap'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'

const TagRoute = ({ data: { allMarkdownRemark: { totalCount, edges: posts } }, pageContext: { tag } }) => {
  return (
    <Layout
      title={`${tag} // tags`}
    >
      <Container>
        <Row>
          <Col>
            <h3>
              {totalCount}
              {` post${totalCount === 1 ? '' : 's' }`}
              {` tagged with `}
              <Badge variant="primary">{tag}</Badge>
            </h3>
            <ul className="taglist">
              {posts.map(post => (
                <li key={post.node.fields.slug}>
                  <Link to={post.node.fields.slug}>
                    {post.node.frontmatter.title}
                  </Link>
                </li>
              ))}
            </ul>
            <p>
              <Link to="/tags/">Browse all tags</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          tags: { in: [$tag] },
          isPublished: { eq: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
